
const configs = {
  api_url: "api/v1/",
  //staging
  // base_url: "https://demo.admin.gastronomia-gmbh.com/",

  // dev
  base_url: "https://admin.op.legiontech.dev/",
  ws_url: "admin.op.legiontech.dev/",
  // base_url: "https://ltiop-demo.admin.legiontech.dev/",

  // //prod
  // base_url: "https://admin.console.gastronomia-gmbh.com/",
  // ws_url: "admin.console.gastronomia-gmbh.com/",
};

export default configs;
